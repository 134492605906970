import { LogType, MstrMessageType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { BridgeUserModel } from "../model/bridgeUserModel";
import { MstrMessage, PendoMstrMessage } from "../model/mstrMessageModel";
import { PendoAccount, PendoModel, PendoVisitor } from "../model/pendoModel";
import { UserAccount } from "../model/userAccountModel";
import { Company } from "../types";
import {
  setPendoModel,
  getPendoModel as getPendoModelFromSession,
  clearPendoModel as clearPendoModelFromSession,
} from "../utility/sessionStorageHelper";
import { getFirstAndLastName } from "../utility/stringHelper";
import { logService } from "./logService";
import { postMessageToDossier } from "./mstrMessagingSerive";

declare global {
  interface Window {
    pendo?: any;
  }
}

export function savePendoModel(pendoModel: PendoModel) {
  setPendoModel(pendoModel);
}

export function getPendoModel(): PendoModel | undefined {
  return getPendoModelFromSession();
}

export function clearPendoModel() {
  clearPendoModelFromSession();
}

export const postPendoMessageToDossier = (id: string) => {
  try {
    const pendoModel = getPendoModel();
    if (!pendoModel) return;
    const mstrMessage: MstrMessage = {
      type: MstrMessageType.Pendo,
      message: pendoModel as PendoMstrMessage,
    };
    if (pendoModel) postMessageToDossier(id, mstrMessage);
  } catch (error) {
    logService.log({
      message: `Error while sending pendo initialization message to the dossier : ${error}`,
      type: LogType.Error,
      method: "postPendoMessageToDossier",
      file: "pendoService.ts",
    });
  }
};

const logError = (message: string, method: string): void => {
  logService.log({
    message,
    method,
    type: LogType.Error,
    file: "pendoService.ts",
  });
};

const mapToVisitor = (user: BridgeUserModel): PendoVisitor => {
  const { firstName, lastName } = getFirstAndLastName(user.name);
  const visitor: PendoVisitor = {
    id: user.sub,
    first_name: firstName,
    last_name: lastName,
    bridge_user_name: user.userName,
    product: currentEnvironment().customConfiguration.product,
  };

  return visitor;
};

export const initializePendo = (
  user: BridgeUserModel,
  userAccount: UserAccount
) => {
  const visitor = mapToVisitor(user);
  const source = userAccount?.sources.find((x) => !!x.parentCommonOrgId);
  const account: Partial<PendoAccount> = {
    id: source?.parentCommonOrgId ?? "cox_automotive",
    name: source?.enterprise ?? "Cox Automotive",
    company_ids: [],
    company_names: [],
  };

  if (window?.pendo === undefined) {
    logError(
      "Unable to initialize as pendo object is not available",
      "initializePendo"
    );
    return;
  }

  window?.pendo?.initialize({
    visitor,
    account,
  });
};

export const updatePendoAccountInfo = (
  source: string,
  persona: string,
  companies: Company[]
): void => {
  let { visitor, account } = getPendoMetadata();
  if (!visitor || !account) {
    return;
  }

  account.persona = persona;
  account.app = source;
  account.company_ids = companies.map((c) => c.id);
  account.company_names = companies.map((c) => c.name);
  window?.pendo.updateOptions({ visitor, account });
};

export const getPendoMetadata = (): Partial<PendoModel> => {
  if (window.pendo === undefined) {
    return {};
  }

  const metadata = window.pendo?.getSerializedMetadata();
  const visitor: PendoVisitor = metadata.visitor;
  let account: PendoAccount = metadata.account;
  return {
    visitor,
    account,
  };
};
