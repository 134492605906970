export const ErrorMessages = {
  noReportAccess:
    "You have not been assigned access to any reports. Please contact customer service.",
  noCurrentReportAccess:
    "You have not been assigned access to this reports. Please contact customer service.",
  mstrDown: "Reports are not available at this time. Please try again later.",
  settingApiDown:
    "Reports permissions are not available at this time. Please try again later.",
  incorrectResource:
    "The page you are trying to access is not available. Please check the link and try again.",
  mstrSessionCloseError:
    "Your session did not close properly. Please try again.",
  unknownError:
    "An unknown error has occurred. Please contact customer service.",
  noDashboardAccess:
    "You have not been assigned access to any dashboards. Please contact customer service.",
  mstrReportLoadError:
    "An error occurred while loading report. Please contact customer service.",
  accountApiDown:
    "Account permissions are not available at this time. Please try again later.",
  sessionError: "Your session has expired. Please sign in again.",
};

export const LoaderMessages = {
  signIn: "Please stand by while we sign you in.",
  mstrSession: "Please stand by while we connect you to your reports.",
  nativeMstrSession: "Please stand by while we connect you to your dashboard.",
  mstrSessionLogout: "Please stand by while we sign you out.",
};
