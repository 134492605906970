export interface Name {
  firstName: string;
  lastName: string;
}

export const getFirstAndLastName = (fullName?: string | null): Name => {
  let name: Name = {
    firstName: "",
    lastName: "",
  };

	if(!fullName?.length) { return name; }


  const index = fullName.lastIndexOf(" ");

  if (index < 0) {
    name.firstName = fullName;
    return name;
  }

  name.firstName = fullName.substring(0, index);
  name.lastName = fullName.substring(index + 1);
  return name;
};
