import { useContext } from "react";
import Dossier from "./Dossier";
import { TokenContext } from "../../../contexts";
import {
  MSTR_ACCOUNTING_DASHBOARD_ID,
  MSTR_ACCOUNTING_REPORT_ID,
  MSTR_CONTAINER_ID,
} from "../../../config/mstrConfig";
import { postAccountingToggleFilterSummaryMessageToDossier } from "../../../services/accountingService";

export interface DossierContainerProps {
  dossierId: string;
  style?: React.CSSProperties;
  containerId?: string;
  pageKey?: string;
  visualId?: string;
  size?: "maximized" | "normal";
  filterUpdate?: () => void;
  resizeButtonVisible?: boolean;
  onGraphicSelected?: () => void;
  mstrLink?: string;
}

function DossierContainer(props: DossierContainerProps) {
  const token = useContext(TokenContext);
  const containerId = props.containerId ?? MSTR_CONTAINER_ID;
  const isAccountingReport = props.dossierId === MSTR_ACCOUNTING_REPORT_ID;
  const isAccountingDashboard =
    props.dossierId === MSTR_ACCOUNTING_DASHBOARD_ID;
  const isBookmark = props.mstrLink?.includes("bookmarks");
  const postAccountingMessage = () =>
    postAccountingToggleFilterSummaryMessageToDossier(
      props.containerId ?? MSTR_CONTAINER_ID,
      props.dossierId === MSTR_ACCOUNTING_REPORT_ID
    );
  return (
    <Dossier
      dossierId={props.dossierId}
      authToken={token}
      style={props.style}
      containerId={containerId}
      pageKey={props.pageKey}
      visualId={props.visualId}
      size={props.size}
      resizeButtonVisible={props.resizeButtonVisible}
      onGraphicSelected={props.onGraphicSelected}
      mstrLink={props.mstrLink}
      isAccountingReport={isAccountingReport}
      isAccountingDashboard={isAccountingDashboard}
      isBookmark={isBookmark}
      postAccountingMessage={postAccountingMessage}
    />
  );
}

export default DossierContainer;
